import { useQuery } from "@apollo/client"
import React from "react"
import { Redirect } from "react-router"
import { PoolHelpUrlQuery, PoolHelpUrlQueryVariables } from "../../../../__generated__/PoolHelpUrlQuery"
import { TPoolRouteProps } from "../../../../routes.d"
import Status from "../../../Base/Status"
import LoadingView from "../../../components/LoadingView"
import { POOL_HELP_URL_QUERY } from "../../queries"

let didRedirect = false

function PoolHelp(props: TPoolRouteProps) {
  const { poolData } = props
  const { poolId: _poolId, entryId: _entryId, segmentsForArea } = poolData
  const currentEntriesForArea = poolData.myEntriesForArea().currentEntriesForArea
  const entryId = _entryId || (currentEntriesForArea.length && currentEntriesForArea[currentEntriesForArea.length - 1].id) || null
  // TODO qac: see comment on sorters.ts
  const poolsForArea = segmentsForArea?.filter((seg) => !!seg.season.challengePoolSlug)
  // if we don't have pool id (lobby) and have entries, use last entry. If we only have one either challenge or manager, we want to go to the help center for that specific game, but if we have more, default to manager. If we dont have entries or logged out user, default to challenge.
  const poolId =
    _poolId ||
    (currentEntriesForArea.length && currentEntriesForArea[currentEntriesForArea.length - 1].pool.id) ||
    (poolsForArea?.length && poolsForArea[poolsForArea.length - 1]?.season.challengePoolId) ||
    ""
  const skip = !poolId
  const poolHelpUrlQuery = useQuery<PoolHelpUrlQuery, PoolHelpUrlQueryVariables>(POOL_HELP_URL_QUERY, {
    variables: { entryId, poolId },
    skip,
  })
  const href = poolHelpUrlQuery.data?.pool.helpDeskUrl
  if (href) {
    if (typeof window !== "undefined") {
      if (!didRedirect) {
        didRedirect = true
        window.location.replace(href)
      }
    } else {
      return (
        <Status code={302}>
          <Redirect to={href} />
        </Status>
      )
    }
  }
  return <LoadingView variant="view" />
}
export default PoolHelp
